import React from "react"
import Layout from "../components/layouts/AboutLayout"
import Seo from "gatsby-theme-blog-starter/src/components/seo/Seo"

import MD from './aboutmd.md'

export default ({ data }) => (
  <>
    <Layout pageTitle="A propos">
     <Seo 
      title="A propos"
      description="A propos"
      tags={["info"]}
      slug="/about" />
      <MD/>
    </Layout>
  </>
)