import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Rendre visible les indicateurs vitaux à la décision`}</h1>
    <h3>{`Focalisé sur les indicateurs clés`}</h3>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`Trop de tableaux de bord sont conçus comme la somme des reporting des différentes fonctions.`}</p>
      </blockquote>
    </blockquote>
    <p>{`S'il n'est pas l'image stricte des objectifs stratégiques, le tableau de bord devient du pdf sur l'imprimante ou de multiples écrans que chacun consultent à sa façon. Au lieu d'une vision commune, la mosaïque de réalités engendre des décisions non alignées.
`}<strong parentName="p">{`Le bon tableau de bord tient sur une page.`}</strong></p>
    <h3>{`Regarde vers l'avant, explique et prédit`}</h3>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`En général dans les organisations beaucoup d'énergie est consacrée à chiffrer précisément le passé ou le temps réel. Mais ... et le futur`}{` `}{`?`}</p>
      </blockquote>
    </blockquote>
    <p>{`Le suivi précis des réalisations est certes vital à l'organisation, mais `}<strong parentName="p">{`c'est devant que cela se passe !`}</strong>{` Il vaut mieux conduire en regardant le pare brise que le rétroviseur pour anticiper difficultés et opportunités. `}</p>
    <h3>{`Rend visible les chiffres et leur donne du sens`}</h3>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`De belles tables de chiffres avec deux décimales et des camemberts aux secteurs colorés à la queue leu leu.`}</p>
      </blockquote>
    </blockquote>
    <p><strong parentName="p">{`C'est le contexte qui donne à une valeur numérique isolée sa signification.`}</strong>{` L'information utile se trouve dans l'historique de l'évolution et la relation avec les autres indicateurs en amont et en aval.`}</p>
    <h1>{`Nous travaillons ou avons travaillé avec:`}</h1>
    <img src="/References.png" width="100%" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      